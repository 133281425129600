
import { useEffect, useState } from 'react';
import LogoTitle from '../../assets/images/logo-d.png';
import { Link } from 'react-router-dom';
import './index.scss';
import AnimatedLetters from '../AnimatedLetters';
import Logo from '../Logo';
import Loader from 'react-loaders';


const Home = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const nameArray = ['e', 'n', 'i', 's','s','e']
    const jobArray = ['w', 'e', 'b', ' ', 'd', 'e', 'v', 'e', 'l', 'o', 'p','e','r', ',']
    const newJobArray= ['a','s','p','i','r','i','n','g' ,' ', 'd','a','t','a']
    const scienceArray=['s','c','i','e','n','t','i','s','t']

    useEffect(() => {
        setTimeout(() => {
          setLetterClass('text-animate-hover')
        }, 6000)
      }, [])


    return (
        <>
        <div className="container home-page">
            <div className="text-zone">
                <h1>
                <span className={letterClass}>H</span>
                <span className={`${letterClass} _12`}>i,</span>
                <br />
                <span className={`${letterClass} _13`}>I'</span>
                <span className={`${letterClass} _14`}>m</span>
                <img src={LogoTitle} alt="developer" />
                <AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={15}/>
                           
                
                <br />
                <AnimatedLetters letterClass={letterClass} strArray={jobArray} idx={21}/>
                <br />
                <AnimatedLetters letterClass={letterClass} strArray={newJobArray} idx={35} />
                <br/>
                <AnimatedLetters letterClass={letterClass} strArray={scienceArray} idx={48} />
                
                </h1>
              
                <h2>Frontend Developer / JavaScript Expert / Python Connoisseur / ML dabbler</h2>
                <Link to="/contact" className='flat-button'>CONTACT ME</Link>
            </div>
            <Logo />
        </div>
        <Loader type='ball-scale-multiple' />
        </>
    );
}

export default Home