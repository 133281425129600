import './index.scss';
import  Sidebar  from '../Sidebar'
import { Outlet } from 'react-router-dom';

const Layout = () => {
    return(
         <div className='App'>
            <title>My Site</title>
            <div className='page'>
                <span className='tags top-tags'>&lt;body&gt;</span>
                <Outlet />

                <span className="tags bottom-tags">
                &lt;body&gt;
                <br />
                <span className='bottom-tag-html'> &lt;hmtl&gt;</span>
                </span>

            </div>
            <Sidebar />
         </div>
    );
}

export default Layout