import './index.scss';
import LogoS from '../../assets/images/rostroBack2.png'
import { useEffect, useState, useRef } from 'react';
import DrawSVGPlugin from 'gsap-trial/DrawSVGPlugin';
import gsap from 'gsap-trial';
import anime from 'animejs/lib/anime.es.js';

const Logo = () => {

  anime({
    targets: '.path',
    strokeDashoffset: [anime.setDashoffset, 0],
    easing: 'easeInOutSine',
    duration: 10000,
    delay: 1000,
    direction: 'alternate',
    loop: false
  });


  const [svgStyle, setSvgStyle] = useState('svg-container')
  const [logoStyle, setLogoStyle] = useState('solid-logo')
  const solidLogoRef = useRef()  

    useEffect(() => {
        setTimeout(() => {
          setSvgStyle('svg-container2')
        }, 5000)
      }, [])
    
    useEffect(() => {
        setTimeout(() => {
          setLogoStyle('solid-logo2')
        }, 5000)
      }, [])

   /*  const bgRef = useRef()
    const outlineLogoRef = useRef()
    

     useEffect(() => {
        gsap.registerPlugin(DrawSVGPlugin)
    
        gsap
          .timeline()
          .to(bgRef.current, {
            duration: 1,
            opacity: 1,
          })
        gsap.to(outlineLogoRef.current, 
     
            {
             delay: 6,
             duration: 3,
             opacity: 1 
            })
        gsap.fromTo(
          solidLogoRef.current,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            delay: 8,
            duration: 3,
          }
        )
      }, [])  */

     

    return (
        <div className='logo-container'>
           <img className={logoStyle} src={LogoS} alt='D'/>
       
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="600.000000pt" height="800.000000pt" viewBox="0 0 600.000000 800.000000"
 preserveAspectRatio="xMidYMid meet">
<g className={svgStyle}  transform="translate(0.000000,800.000000) scale(0.100000,-0.100000)"
>
<path className='path' d="M2685 7896 c-99 -33 -127 -46 -330 -153 -360 -191 -725 -405 -963
-565 -566 -382 -762 -703 -986 -1617 -121 -490 -81 -745 199 -1276 279 -530
317 -621 319 -770 1 -108 -20 -185 -67 -247 -50 -65 -107 -100 -170 -106 -49
-4 -57 -1 -87 25 -25 22 -33 39 -37 75 -8 68 12 112 85 189 47 51 62 73 54 81
-25 25 -181 -135 -230 -235 -79 -163 -59 -305 57 -409 146 -132 357 -84 416
93 15 44 17 73 12 169 -4 63 -9 131 -13 150 l-6 35 21 -25 c34 -43 111 -177
137 -240 50 -125 49 -266 -3 -361 -33 -61 -79 -109 -201 -207 -167 -135 -233
-225 -248 -335 -15 -107 35 -183 196 -297 109 -77 131 -101 156 -166 15 -39
15 -48 3 -81 -21 -55 -68 -83 -141 -83 -116 0 -173 46 -304 246 -50 78 -96
144 -102 148 -18 11 -60 -52 -72 -107 -21 -93 22 -193 117 -271 53 -44 93
-114 93 -161 0 -51 -30 -95 -65 -95 -13 0 -27 -5 -30 -10 -8 -12 80 -200 122
-262 46 -68 46 -69 6 -46 -66 38 -176 157 -214 230 -18 38 -39 68 -46 68 -33
0 -51 -154 -29 -258 37 -178 141 -306 317 -390 149 -72 215 -87 384 -87 158 1
223 14 343 71 96 46 192 136 232 218 17 35 35 94 42 137 14 93 1 141 -71 269
-83 145 -84 147 -79 216 4 58 9 70 67 155 38 53 72 116 82 151 33 104 23 245
-25 358 -4 11 0 15 16 17 42 2 173 44 213 68 54 31 126 109 153 166 55 114 64
279 27 523 -8 55 -14 101 -12 103 8 7 262 -654 331 -862 82 -244 110 -359 110
-460 1 -155 -23 -194 -41 -67 -27 182 -129 421 -240 559 -69 86 -199 120 -262
67 -33 -28 -54 -84 -45 -123 3 -14 34 -84 69 -156 98 -200 163 -405 195 -615
34 -224 30 -529 -11 -718 -29 -138 -34 -182 -20 -182 15 0 27 32 56 155 69
294 252 661 461 925 32 41 72 95 87 118 43 66 150 168 206 196 27 14 76 28
108 32 138 14 210 56 403 236 74 68 176 157 225 197 50 40 119 100 155 132 68
61 213 186 383 330 115 98 112 98 140 -1 33 -113 136 -219 254 -262 88 -33
255 -37 364 -9 230 58 387 212 422 416 14 80 -1 162 -37 209 -29 38 -173 104
-247 113 l-50 6 74 76 c40 42 84 97 96 124 l23 47 33 -43 c101 -133 200 -320
230 -437 23 -86 23 -244 0 -330 -46 -178 -167 -320 -368 -430 -209 -115 -286
-163 -346 -214 -118 -101 -198 -224 -245 -373 -127 -410 45 -806 425 -982 143
-65 194 -76 364 -76 142 0 155 2 238 32 123 44 189 86 288 183 60 60 95 104
117 149 31 62 32 69 32 188 l0 123 -81 -60 c-204 -151 -380 -192 -529 -123
l-55 26 42 8 c106 20 183 101 183 194 0 40 -7 43 -52 15 -40 -24 -88 -26 -134
-4 -75 36 -73 60 6 88 243 86 439 271 571 540 40 81 49 109 48 150 l0 51 -19
-53 c-28 -77 -82 -188 -128 -257 -127 -196 -281 -322 -492 -402 -75 -28 -85
-35 -82 -55 3 -27 46 -74 86 -95 21 -11 47 -14 93 -10 44 3 63 1 63 -7 0 -26
-52 -85 -93 -105 -48 -23 -82 -26 -123 -11 -19 8 -30 8 -38 0 -19 -20 74 -95
146 -119 153 -52 325 -7 516 133 l62 46 0 -48 c-1 -269 -290 -525 -619 -548
-228 -16 -459 73 -626 240 -91 92 -144 176 -182 291 -24 72 -27 95 -27 230 -1
179 11 233 83 380 84 171 173 252 421 385 187 100 234 133 320 219 119 120
177 255 187 431 11 201 -66 401 -252 653 -36 48 -69 87 -74 87 -5 0 -16 -21
-25 -47 -37 -110 -185 -263 -252 -263 -18 0 -39 -3 -48 -6 -21 -8 -21 -34 0
-34 10 0 -13 -31 -59 -80 -114 -121 -152 -217 -127 -321 17 -72 85 -141 161
-163 66 -20 92 -20 156 -1 140 42 220 226 163 378 -24 65 -93 143 -156 175
l-39 21 35 1 c83 1 247 -63 281 -110 27 -37 39 -114 28 -184 -38 -233 -276
-405 -559 -406 -240 0 -413 142 -428 352 -3 38 -9 68 -15 68 -6 0 -20 -9 -32
-20 l-22 -21 -95 118 c-115 143 -178 227 -178 238 0 4 32 41 71 82 121 128
244 337 299 508 12 39 29 81 38 95 9 14 28 50 43 81 51 108 119 162 221 177
l53 7 3 66 c2 56 -3 81 -34 165 -34 92 -37 107 -39 224 -2 117 -4 132 -39 223
-46 124 -63 223 -71 412 -12 283 -36 418 -94 533 -16 31 -77 125 -135 207
-159 227 -178 272 -226 547 -31 181 -78 293 -162 384 -53 58 -88 72 -246 100
-167 30 -252 68 -329 146 -121 124 -185 300 -207 571 l-5 67 37 0 c54 0 212
-27 323 -55 402 -102 790 -325 1095 -629 345 -346 594 -811 707 -1326 42 -189
57 -313 67 -546 9 -240 17 -274 66 -313 42 -33 103 -25 212 29 71 35 104 60
179 135 101 101 103 101 103 0 0 -141 -64 -289 -170 -395 -36 -36 -125 -108
-199 -160 -73 -52 -160 -121 -192 -154 -110 -110 -156 -248 -147 -431 10 -183
96 -285 221 -261 105 20 102 20 127 -10 20 -26 22 -37 17 -106 -3 -44 -1 -78
4 -78 5 0 31 12 59 28 59 32 65 30 132 -53 93 -115 149 -255 188 -475 l18
-105 1 93 c1 130 -45 300 -112 412 -37 63 -121 162 -150 177 -26 13 -32 12
-68 -5 l-40 -20 6 28 c9 42 -4 98 -32 131 -28 33 -57 36 -142 18 -73 -15 -130
11 -160 75 -54 110 -47 307 15 434 47 96 115 164 284 282 224 157 312 265 355
435 28 110 15 305 -20 305 -8 0 -15 -4 -15 -9 0 -21 -109 -146 -164 -188 -144
-109 -265 -140 -310 -80 -19 26 -22 47 -28 230 -26 786 -316 1468 -834 1967
-366 352 -821 577 -1302 644 -172 24 -162 27 -162 -46 0 -289 106 -558 267
-679 85 -65 181 -100 325 -119 107 -15 152 -36 210 -97 83 -90 112 -166 154
-405 39 -229 68 -297 213 -503 193 -275 211 -329 231 -710 12 -224 25 -297 75
-443 33 -93 35 -109 33 -209 -1 -99 1 -115 30 -192 18 -47 31 -86 29 -87 -1
-1 -33 32 -71 73 -64 71 -68 78 -62 112 17 93 17 173 2 281 -13 97 -23 130
-60 207 -25 51 -62 118 -84 150 -89 131 -101 177 -82 316 16 112 10 151 -33
214 -87 130 -283 194 -456 148 -56 -14 -222 -82 -359 -147 -48 -23 -84 -65
-102 -122 -11 -34 -10 -41 7 -65 16 -20 30 -27 65 -29 38 -3 59 4 129 43 311
170 467 215 575 166 62 -28 98 -106 102 -222 4 -83 -9 -179 -27 -208 -13 -20
-93 47 -85 71 8 26 -85 114 -154 146 -176 81 -481 -14 -574 -179 -28 -50 -56
-58 -104 -30 -19 12 -39 19 -44 16 -4 -3 -16 -47 -26 -97 -30 -158 -21 -460
18 -590 15 -52 42 -78 81 -78 69 0 107 -55 107 -153 0 -50 -5 -70 -21 -91 -27
-34 -36 -33 -60 9 -39 66 -94 52 -125 -32 -25 -66 -42 -92 -75 -109 -60 -31
-90 -14 -143 78 -32 55 -79 98 -107 98 -28 0 -50 -41 -43 -78 6 -27 4 -32 -13
-32 -39 0 -108 44 -130 85 -25 44 -31 122 -12 158 6 12 53 65 104 117 73 75
97 107 113 153 57 151 28 305 -81 439 -46 56 -47 57 -33 90 8 19 24 71 36 117
23 92 18 156 -19 228 -50 98 -143 131 -431 153 -80 6 -191 18 -246 26 -75 12
-107 13 -122 5 -18 -10 -23 -3 -65 107 -61 155 -70 199 -65 287 4 57 12 90 36
138 55 112 132 166 342 243 145 53 416 190 527 266 126 87 273 216 273 240 0
14 -5 18 -16 14 -13 -5 -14 -2 -9 11 5 10 19 54 33 98 23 70 26 98 26 230 1
131 -2 160 -22 225 -44 140 -95 212 -174 244 -55 23 -159 20 -243 -8z m267
-42 c91 -64 143 -218 142 -419 -1 -133 -18 -218 -65 -324 -16 -35 -29 -69 -30
-75 0 -16 -111 -105 -224 -180 -118 -79 -329 -184 -510 -256 -71 -29 -156 -65
-188 -82 -108 -58 -175 -157 -198 -293 -15 -88 -1 -160 66 -331 25 -64 53
-149 62 -188 8 -39 21 -82 28 -96 44 -84 44 -323 2 -470 -59 -203 -102 -275
-273 -450 -72 -74 -138 -149 -147 -166 -26 -51 -30 -105 -17 -231 26 -262 80
-451 188 -663 48 -95 67 -144 78 -205 8 -44 26 -104 40 -133 29 -61 76 -258
98 -413 19 -125 21 -325 5 -401 -24 -113 -92 -203 -191 -255 -46 -24 -66 -28
-153 -30 -55 -1 -101 -7 -103 -12 -2 -5 10 -36 27 -69 78 -154 62 -329 -44
-477 -101 -142 -108 -200 -41 -327 24 -46 58 -110 77 -143 32 -56 34 -66 34
-150 0 -80 -3 -97 -31 -156 -116 -244 -491 -353 -822 -240 -168 58 -281 151
-350 290 -45 90 -55 134 -50 236 l3 70 34 -55 c50 -81 154 -179 237 -223 71
-37 104 -46 104 -28 0 5 -25 42 -55 83 -54 72 -145 237 -145 263 0 7 9 17 20
20 46 15 75 96 58 163 -8 34 -29 64 -89 127 -93 99 -119 146 -119 215 0 43 21
110 34 110 2 0 39 -55 82 -123 135 -210 199 -261 329 -262 107 0 170 46 182
133 12 92 -37 161 -199 275 -169 120 -200 206 -126 349 37 70 88 124 209 220
149 119 203 186 233 287 42 142 -4 310 -139 518 l-57 87 -2 125 c-3 190 2 177
-339 841 -165 321 -216 470 -238 684 -15 145 3 287 71 566 217 880 414 1199
969 1564 411 271 1147 679 1296 719 92 25 186 17 237 -19z m1126 -2024 c156
-55 245 -167 223 -280 -6 -29 -9 -36 -10 -19 -4 88 -63 188 -128 215 -89 38
-263 4 -432 -84 -58 -30 -135 -70 -171 -89 -48 -24 -76 -33 -105 -31 -37 3
-40 6 -43 34 -4 40 34 87 97 118 82 42 287 125 342 140 77 21 160 19 227 -4z
m-1903 -70 c22 -5 108 -14 190 -20 275 -20 356 -39 417 -97 25 -24 66 -118 56
-129 -3 -2 -39 11 -80 31 -184 85 -339 117 -538 113 -74 -1 -140 1 -147 5 -16
10 -36 74 -29 92 6 17 69 20 131 5z m235 -144 c52 -8 133 -27 180 -42 87 -27
233 -95 245 -114 3 -6 -1 -35 -10 -66 l-16 -55 -27 19 c-63 44 -182 102 -237
117 -74 19 -183 19 -254 0 -78 -20 -163 -64 -159 -82 4 -17 8 -16 84 19 108
50 242 59 349 23 54 -19 205 -106 218 -126 6 -9 7 -24 4 -33 -6 -14 -11 -13
-45 11 -74 54 -256 125 -321 126 -96 1 -311 -88 -311 -129 0 -21 112 -100 168
-118 51 -17 66 -18 237 -16 90 2 120 -2 165 -19 30 -11 57 -17 61 -13 3 4 18
24 31 45 l26 38 36 -44 c19 -24 48 -70 63 -103 25 -53 28 -70 28 -164 0 -136
-18 -173 -136 -293 -98 -99 -125 -150 -116 -217 13 -102 83 -168 186 -177 l62
-6 -3 44 c-3 38 0 44 17 44 13 0 36 -24 70 -75 62 -92 87 -108 142 -92 51 15
109 72 118 114 8 38 31 78 45 78 5 0 21 -18 35 -40 32 -51 62 -53 101 -7 41
49 83 34 245 -89 155 -117 203 -185 184 -261 -11 -45 -30 -68 -97 -115 -77
-53 -102 -67 -65 -36 15 13 27 31 27 41 0 15 -13 17 -107 17 -80 0 -134 6
-209 24 -76 19 -130 25 -225 26 -68 0 -155 2 -194 2 -57 2 -91 -5 -180 -35
-197 -66 -266 -67 -384 -7 -60 30 -101 31 -101 3 0 -8 9 -17 20 -20 11 -3 56
-51 99 -107 94 -121 224 -249 305 -301 178 -113 394 -136 551 -57 73 36 115
74 194 172 89 109 145 162 209 194 28 14 52 29 54 34 1 4 24 23 51 41 46 31
77 77 91 134 11 45 -11 102 -60 156 -53 59 -234 195 -308 231 l-51 25 -1 55
c-2 107 -41 168 -121 189 -71 19 -82 40 -104 206 -16 122 -7 379 14 403 8 9
17 26 19 37 3 18 10 20 55 17 46 -3 55 -1 76 22 36 39 39 32 10 -30 -28 -61
-22 -85 15 -65 33 18 96 13 211 -15 174 -43 277 -26 384 63 83 69 92 72 130
35 49 -47 62 -42 83 32 l18 65 31 -62 c17 -35 45 -83 63 -109 17 -25 54 -93
81 -151 59 -127 76 -212 69 -359 -4 -93 -10 -119 -62 -270 -45 -130 -59 -188
-66 -263 -17 -179 -38 -231 -208 -525 -216 -373 -334 -510 -584 -678 -128 -86
-272 -171 -367 -216 -186 -89 -431 -69 -672 55 -183 94 -278 179 -447 404
-331 439 -441 690 -487 1108 -22 205 -24 199 139 367 73 74 152 164 175 199
113 167 173 417 149 624 -17 153 -20 141 37 148 91 11 179 9 275 -5z m1575
-155 c49 -23 144 -101 123 -101 -3 0 -34 13 -69 30 -138 64 -271 63 -435 -4
-48 -20 -89 -34 -92 -32 -5 6 74 58 127 86 79 40 114 49 201 49 74 1 93 -3
145 -28z m-16 -80 c33 -10 81 -31 107 -46 l46 -27 -24 -16 c-12 -8 -38 -29
-57 -45 -42 -36 -127 -77 -161 -77 -24 0 -24 0 -4 15 74 51 44 170 -51 203
-58 20 78 14 144 -7z m-1441 -46 c26 -31 27 -46 7 -85 -21 -41 -66 -62 -123
-58 -40 3 -49 8 -69 37 -27 40 -30 87 -6 120 15 22 20 23 92 17 65 -5 79 -10
99 -31z m1315 8 c42 -21 53 -60 30 -103 -23 -44 -55 -60 -120 -60 -45 0 -54 4
-77 31 -20 23 -26 41 -26 77 0 56 7 68 40 75 37 7 121 -3 153 -20z m-1553 -40
c0 -27 7 -61 15 -77 9 -16 13 -31 10 -34 -8 -8 -96 36 -133 67 l-34 27 59 32
c78 42 83 41 83 -15z m1323 3 c-4 -29 0 -55 10 -75 18 -35 18 -35 -64 -18
l-55 12 12 31 c12 28 78 94 95 94 4 0 4 -20 2 -44z m-996 13 c47 -17 143 -83
143 -97 0 -8 -7 -26 -16 -41 l-16 -27 -66 17 c-37 10 -80 18 -95 18 l-29 1 21
34 c12 18 21 50 21 70 0 41 -1 40 37 25z m2064 -1009 c42 -63 37 -92 -17 -101
-94 -16 -165 -67 -215 -153 l-30 -51 5 45 c3 25 8 74 11 110 4 39 24 113 52
188 l46 123 59 -58 c33 -32 73 -78 89 -103z m-1569 -481 c54 -10 77 -10 122 1
50 12 63 11 163 -13 59 -15 143 -29 186 -33 72 -6 78 -8 75 -28 -3 -18 -14
-23 -78 -33 -107 -17 -549 -63 -607 -63 -67 0 -153 16 -243 46 -99 32 -112 41
-74 49 16 3 85 24 154 45 165 52 173 52 302 29z m-477 -124 c151 -63 232 -78
390 -72 125 5 428 34 544 53 l43 7 -97 -116 c-132 -159 -211 -215 -339 -238
-154 -29 -331 33 -499 175 -67 56 -197 212 -197 236 0 20 1 20 38 4 20 -8 73
-31 117 -49z m1639 -46 c-35 -72 -139 -239 -148 -239 -2 0 37 72 88 159 50 88
92 158 94 156 2 -2 -13 -36 -34 -76z m-3721 -472 c64 -98 222 -75 318 45 43
55 43 55 54 -78 17 -196 -58 -304 -211 -304 -201 0 -315 224 -218 426 l28 59
5 -60 c3 -34 14 -73 24 -88z m1542 -32 c170 -228 298 -340 488 -430 135 -64
221 -86 362 -92 149 -6 223 9 350 73 237 120 450 267 587 405 l97 99 79 -103
c43 -56 105 -133 136 -171 31 -38 55 -73 54 -77 -4 -11 -218 -196 -351 -305
-65 -52 -121 -102 -123 -109 -3 -8 -58 -56 -122 -107 -64 -51 -164 -138 -222
-192 -193 -182 -266 -227 -396 -244 -40 -6 -91 -19 -116 -29 -92 -41 -222
-188 -428 -487 -90 -129 -185 -304 -250 -459 l-39 -92 -1 148 c0 357 -72 672
-221 977 -77 158 -83 192 -45 237 21 24 31 28 75 27 96 -1 158 -67 264 -279
68 -135 101 -239 122 -380 9 -60 18 -112 21 -117 11 -17 43 24 64 79 42 111
23 257 -71 548 -49 155 -225 626 -315 848 -60 147 -114 317 -114 362 0 24 -3
27 115 -130z m2915 -5 c149 -76 206 -236 134 -377 -43 -84 -103 -123 -191
-123 -175 0 -264 154 -183 317 29 58 164 203 189 203 7 0 29 -9 51 -20z"/>
</g>

</svg> 
{/* <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 612 792" >

<g className="svg-container" transform="translate(0.000000,897.000000) scale(0.100000,-0.100000)"
 > 

<g>
    <g>
      <path className='path' d="M603.7,432c0,163.6-121.1,297.6-274.4,297.6H181.8c-59.6,0-114.3-8.3-114.3-54.5c0-19,10.7-42.7,19.5-56.9
			c13.7-24.9,23.4-105.5,25.4-186.1c-2-79.4-11.7-160.1-25.4-185c-8.8-14.2-19.5-39.1-19.5-58.1c0-45.1,54.7-54.5,114.3-54.5h147.5
			C482.6,134.4,603.7,268.4,603.7,432z M428.8,432c0-84.2-37.1-240.7-106.5-240.7c-42,0-58.6,86.5-58.6,240.7
			c0,155.3,16.6,241.9,58.6,241.9C391.7,673.9,428.8,517.4,428.8,432z" />
    </g>
    <g>
      <path class="st0" d="M562.7,452.5c0,163.6-121.1,297.6-274.4,297.6H140.8c-59.6,0-114.3-8.3-114.3-54.5c0-19,10.7-42.7,19.5-56.9
			c13.7-24.9,23.4-105.5,25.4-186.1c-2-79.4-11.7-160.1-25.4-185c-8.8-14.2-19.5-39.1-19.5-58.1c0-45.1,54.7-54.5,114.3-54.5h147.5
			C441.6,154.9,562.7,288.9,562.7,452.5z M387.8,452.5c0-84.2-37.1-240.7-106.5-240.7c-42,0-58.6,86.5-58.6,240.7
			c0,155.3,16.6,241.9,58.6,241.9C350.7,694.4,387.8,537.9,387.8,452.5z" />
    </g>
  </g>

</svg>*/}


         
        </div>
    )
}

export default Logo