import Loader from 'react-loaders'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useRef, useState } from 'react'
import emailjs from '@emailjs/browser'

const Contact = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const refForm = useRef()

    useEffect(() => {
        setTimeout(() => {
          setLetterClass('text-animate-hover')
        }, 3000)
      }, [])
    


    const sendEmail = (e) => {
        e.preventDefault()
    
        emailjs
          .sendForm('default_service', 'template_c0y0zba', refForm.current, 'J0vxCuiya3lz0sS-N')
          .then(
            () => {
              console.log('success')
              alert('Message successfully sent!')
              window.location.reload(false)
            },
            () => {
              console.log('failed')  
              alert('Failed to send the message, please try again')
            }
          )
      }

    return (
        <>
            <div className='container contact-page'>
                <div className='text-zone'>
                    <h1>
                        <AnimatedLetters letterClass={letterClass} strArray={['C','o','n','t','a','c','t',' ','m','e']}
                        idx={15}/>
                    </h1>
                    <p>
                    I'm excited to connect with fellow professionals and potential collaborators as I transition into the field of data. I'm eager to leverage my unique blend of technical and analytical expertise to drive innovative solutions. Let's discuss how I can contribute to your projects and help solve real-world problems using data-driven insights
                    </p>
                    <div className='contact-form'>
                        <form ref={refForm} onSubmit={sendEmail}>
                            <ul>
                                <li className='half'>
                                    <input type='text' name="name" placeholder='name' required />
                                </li>
                                <li className='half'>
                                    <input type='email' name="email" placeholder='email' required />
                                </li>
                                <li>
                                    <input placeholder='subject' type='text' name='subject' required/>

                                </li>
                                <li >
                                    <textarea  placeholder='message' name='message' required />

                                </li>
                                <li>
                                    <input type="submit" className='flat-button' value='SEND' />
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
            </div>
            <Loader type="ball-scale-multiple" />
        </>
    )
}


export default Contact